import { ConfigProvider } from "antd";
import 'antd/dist/antd.css';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/custom.css";

const validateMessages = {
    required: "Campo obrigatório!",
};

ReactDOM.render(
    <ConfigProvider form={{validateMessages}}>
        <App />   
    </ConfigProvider>    
, document.getElementById("root"))
