import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Select, Spin, Table, Tag, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import CustomPagination from '../../../components/CustomPagination';
import LayoutFull from '../../../components/LayoutFull';
import api from '../../../services/api.service';

type FormFilter = {
    date_start: string;
    date_end: string;
    plot_id: string;
    product_type_id: string;
}

export function PackagedLossReport(){

    const [loading, setLoading] = useState<boolean>(false)
    const [loadingButtonPdf, setLoadingButtonPdf] = useState<boolean>(false)

    const [dataTable, setDataTable] = useState([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [plotList, setPlotList] = useState<any[]>([])
    const [productTypeList, setProductTypeList] = useState<any[]>([])
    const [filters, setFilters] = useState<FormFilter>({} as FormFilter)

    const { Option } = Select

    const columns: any[] = [
        {
            title: 'UP',
            key: 'plot',
            dataIndex: 'plot',
            ellipsis: 'enable',
            render: (text: string, record: any) => {
                return (
                    <div>
                        <strong>{text}</strong><br/>
                        { record.user && <Tag color="red">{record.user}</Tag> }
                    </div>
                )
            }
        },        
        {
            title: 'Cultura',
            key: 'product_type',
            dataIndex: 'product_type',
            ellipsis: 'enable',
            render: (text: any) => {
                return text
            }
        },
        {
            title: 'Peso',
            key: 'weight',
            dataIndex: 'weight',
            render: (text: any) => {
                return `${text} KG`
            }
        },
        {
            title: 'Data lançamento',
            key: 'created_at',
            dataIndex: 'created_at',
            render: (text: any) => {
                return `${moment(text).format('DD/MM/YYYY')}`
            }
        }
    ];

    const getData = useCallback( async () => {
        try{
            const params = {...filters, ...{page: currentPage}}
            setLoading(true)
            const { data } = await api.get('harvest-packaged-loss', {params})
            setDataTable(data)
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoading(false)
        }
    }, [currentPage, filters]) 
    
    const handlerFilter = async (values: any) => {
        setCurrentPage(1)
        setFilters({
            date_start: values.date_start && moment(values.date_start).format('YYYY-MM-DD'), 
            date_end: values.date_end && moment(values.date_end).format('YYYY-MM-DD'),
            plot_id: values.plot_id,
            product_type_id: values.product_type_id,
        });
    }

    const handlerDownloadPdf = async() => {
        try{
            setLoadingButtonPdf(true)
            const { data } = await api.get('reports/harvest-packaged-loss', {params: filters, responseType: 'blob'})
            const file = new Blob([data], {type: 'application/pdf'});
            const url = URL.createObjectURL(file);
            window.open(url);
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoadingButtonPdf(false)
        }
    }

    const searchProductType = async (search: string) => {
        try{
            setLoading(true)
            const { data } = await api.get(`product-type`, {params: {filters: {name: search}}})            
            setProductTypeList(data)
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoading(false)
        }
    }

    const searchPlot = async (search: string) => {
        try{
            setLoading(true)
            const { data } = await api.get(`plot`, {params: {filters: {name: search}}})            
            setPlotList(data)
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoading(false)
        }
    }    

    useEffect(() => {         
        getData()
    }, [getData])

    return(
        <LayoutFull headerTitle="Relatório Refugo">     
            <Form
                onFinish={handlerFilter}
                onFinishFailed={() => {}}
                autoComplete="off"
                layout="vertical"
            >
                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item
                            name="plot_id"
                            label="Lote"                 
                        >
                            <Select
                                labelInValue={false}
                                placeholder="Digite o nome do lote para buscar..."
                                notFoundContent={!plotList.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                filterOption={false}
                                onSearch={(value) => searchPlot(value)}
                                style={{ width: '100%' }}
                                showSearch
                                allowClear
                            >
                                {(plotList || []).map((item: any) => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="product_type_id"
                            label="Variedade Fruta"                 
                        >
                            <Select
                                labelInValue={false}
                                placeholder="Digite o nome da fruta..."
                                notFoundContent={!productTypeList.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                filterOption={false}
                                onSearch={(value) => searchProductType(value)}
                                style={{ width: '100%' }}
                                showSearch
                                allowClear
                            >
                                {(productTypeList || []).map((item: any) => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>  
                        <Row gutter={8}>
                            <Col span={12}>                      
                                <Form.Item                        
                                    name="date_start"
                                    label="Data inicial" 
                                >
                                    <DatePicker format='DD/MM/YYYY' placeholder={'Data Inicial'}/>
                                </Form.Item>   
                            </Col>
                            <Col span={12}>                               
                                <Form.Item                        
                                    name="date_end"
                                    label="Data Final" 
                                >
                                    <DatePicker format='DD/MM/YYYY' placeholder={'Data Final'}/>
                                </Form.Item> 
                            </Col>
                        </Row>       
                    </Col>
                    <Col span={5}>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{marginTop: 30}}>
                            Buscar
                        </Button>
                        <Button 
                            onClick={() => handlerDownloadPdf()} 
                            htmlType="button" 
                            icon={<FilePdfOutlined />} 
                            style={{marginTop: 30, backgroundColor: '#3d85c6', color: '#fff', marginLeft: 8}}
                            loading={loadingButtonPdf}
                        >
                            Download PDF
                        </Button>
                    </Col>
                </Row>
            </Form>                      
            <Table 
                columns={columns} 
                dataSource={dataTable} 
                rowKey={() => Math.random()} 
                loading={loading}
                pagination={false} 
                tableLayout="auto"               
            />
            <CustomPagination 
                onPrevious={() => setCurrentPage(currentPage - 1)}
                onNext={() => setCurrentPage(currentPage + 1)}
                currentPage={currentPage}
                dataTableLength={Boolean(dataTable.length)}
            />                     
        </LayoutFull>
    )
}