import {
    CheckCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined
} from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Form, Input, message, notification, Pagination, Popconfirm, Row, Select, Space, Spin, Switch, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import InsideError from '../../components/InsideError';
import LayoutFull from "../../components/LayoutFull";
import api from "../../services/api.service";

export function Plot() {

    const [dataTable, setDataTable] = useState([])
    const [loading, setLoading] = useState<boolean>(false)
    const [pagination, setPagination] = useState({} as any)
    const [errors, setErrors] = useState([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [showDrawer, setShowDrawer] = useState(false)
    const [drawerTitle, setDrawerTitle] = useState('')
    const [productTypeList, setProductTypeList] = useState<any[]>([])

    const [form] = Form.useForm()
    const { Option } = Select

    const columns: any[] = [
        {
            title: 'Nome',
            key: 'name',
            dataIndex: 'name',
            ellipsis: 'enable',
            sorter: (a: any, b: any) => a.name.length - b.name.length,
        },
        {
            title: 'Cultura',
            key: 'product_type',
            dataIndex: 'product_type',
            ellipsis: 'enable',
            render: (text: string) => {
                return <strong>{text}</strong>
            }
        },
        {
            title: 'Ativo',
            dataIndex: 'active',
            key: 'active',
            align: 'center',
            render: (value: boolean, record: any) => {
                return (
                    <Switch size="small" defaultChecked={value} onClick={(value) => handleToggleActive(record.id, value, record)} />
                )
            },
            sorter: (a: any, b: any) => a?.active - b?.active,
        },
        {
            title: 'Ações',
            key: 'actions',
            align: 'center',
            render: (text: any, record: any) => (
                <Space size="small">
                    <Button type="link" onClick={() => hidrateFormWithValues(record)}>
                        <EditOutlined />{' '}Editar
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Deseja realmente excluir esse item?"
                        onConfirm={() => remove(record.id)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button type="link" style={{ color: 'red' }} >
                            <DeleteOutlined />{' '}Excluir
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await api.get('plot', { params: { page: currentPage } })
            setDataTable(data)
        } catch (err: any) {
            message.error(err.message)
        } finally {
            setLoading(false)
        }
    }, [currentPage])

    const onChangePage = (page: number) => {
        setCurrentPage(page)
    }

    const hidrateFormWithValues = useCallback((values: any) => {
        form.setFieldsValue(values)
        setShowDrawer(true)
        setDrawerTitle('Editar cadastro')
        setErrors([])

    }, [form])

    const handleToggleActive = async (id: string, active: boolean, record: any) => {
        try {
            record.active = active
            await api.put(`plot/${id}`, record)
            message.success('Registro atualizado com sucesso')
        } catch (err: any) {
            message.error(err?.response?.data?.message)
        }
    }

    const remove = useCallback(async (id: string) => {
        try {
            await api.delete(`plot/${id}`)
            message.success("Registro excluido com sucesso")
            getData()
        } catch (err: any) {
            message.error(err.message)
        }
    }, [getData])

    const openDrawer = () => {
        setErrors([])
        form.resetFields()
        setShowDrawer(true)
        setDrawerTitle('Novo cadastro')
    }

    const saveOrCreate = async (values: any) => {
        if (values.id) {
            try {
                await api.put(`plot/${values.id}`, values)
                setShowDrawer(false);
                notification.open({
                    message: 'Sucesso',
                    description: 'Registro atualizado com sucesso',
                    icon: <CheckCircleOutlined style={{ color: 'green' }} />
                });
                getData()
            } catch (err: any) {
                setErrors(err?.response?.data?.errors)
            }
        } else {
            try {
                await api.post('plot', values)
                setShowDrawer(false);
                notification.open({
                    message: 'Sucesso',
                    description: 'Registro inserido com sucesso',
                    icon: <CheckCircleOutlined style={{ color: 'green' }} />
                });
                getData()
            } catch (err: any) {
                setErrors(err?.response?.data?.errors)
            }
        }
    }

    const searchProductType = async (search: string) => {
        try {
            setLoading(true)
            const { data } = await api.get(`product-type`, { params: { filters: { name: search } } })
            setProductTypeList(data)
        } catch (err: any) {
            message.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
        setPagination({})
    }, [getData])

    return (
        <LayoutFull headerTitle="UP - Unidades de Produção">
            <Row>
                <Col offset={21} span={3} style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: 18 }}>
                    <Button type="primary" block onClick={openDrawer}>
                        <PlusOutlined />{' '}Novo
                    </Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={dataTable}
                rowKey={() => Math.random()}
                loading={loading}
                pagination={false}
            />
            <Pagination
                pageSize={20}
                style={{ float: 'right', margin: '5px' }}
                current={currentPage}
                onChange={onChangePage}
                total={pagination.total}
                showTotal={total => `Total ${total} registros`}
            />
            <Drawer
                title={drawerTitle}
                width={720}
                onClose={() => setShowDrawer(false)}
                maskClosable={false}
                visible={showDrawer}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={saveOrCreate}
                    initialValues={{
                        id: ''
                    }}
                >
                    <Row gutter={16}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input readOnly name="id" />
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Nome"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="product_type_id"
                                label="Produto"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    labelInValue={false}
                                    placeholder="Digite o nome do produto para buscar..."
                                    notFoundContent={!productTypeList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                    filterOption={false}
                                    onSearch={(value) => searchProductType(value)}
                                    style={{ width: '100%' }}
                                    showSearch
                                    allowClear
                                >
                                    {productTypeList.map((item: any) => (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="active"
                                label="Status"
                                rules={[{ required: true }]}
                            >
                                <Select placeholder="Status">
                                    <Option value="true">Ativo</Option>
                                    <Option value="false">Inativo</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Button onClick={() => setShowDrawer(false)} style={{ marginRight: 8 }} block htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button type="primary" htmlType="submit" block>
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {Boolean(errors.length) && <InsideError errors={errors} />}
            </Drawer>
        </LayoutFull>
    )
}