import {
    CheckCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined, LockOutlined
} from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Form, Input, message, Modal, notification, Pagination, Popconfirm, Row, Select, Space, Spin, Switch, Table, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import InsideError from '../../components/InsideError';
import LayoutFull from "../../components/LayoutFull";
import api from "../../services/api.service";

export function User(){

    const [dataTable, setDataTable] = useState([])
    const [loading, setLoading] = useState<boolean>(false)
    const [pagination, setPagination] = useState({} as any)
    const [errors, setErrors] = useState<string | string[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [showDrawer, setShowDrawer] = useState(false)
    const [drawerTitle, setDrawerTitle] = useState('')
    const [profileList, setProfileList] = useState<any[]>([])
    const [user, setUser] = useState<any>(null)
    const [showModalResetPassword, setShowModalResetPassword] = useState<boolean>(false)

    const [form] = Form.useForm()
    const { Option } = Select 

    const columns: any[] = [
        {
          title: 'Nome',
          key: 'name',
          dataIndex: 'name',
          sorter: (a: any, b: any) => a.name.length - b.name.length,
        },
        {
            title: 'Usuário',
            key: 'username',
            dataIndex: 'username',
            sorter: (a: any, b: any) => a.username.length - b.username.length,
        },
        {
            title: 'Perfil',
            key: 'profile',
            dataIndex: 'profile',
            sorter: (a: any, b: any) => a.profile.length - b.profile.length,
            render: (value: string, record: any) => {
                return <Tag color="blue">{record.profile}</Tag>
            }
        },        
        {
            title: 'Ativo',
            dataIndex: 'active',
            key: 'active',
            align: 'center',
            render: (value: boolean, record: any) => {
              return (
                <Switch size="small" defaultChecked={value} onClick={(value) => handleToggleActive(record.id, value, record)} />
              )
            },
            sorter: (a: any, b: any) => a?.active - b?.active,
          },        
        {
            title: 'Ações',
            key: 'actions',
            align: 'center',
            render: (text: any, record: any) => (
                <Space size="small">
                    <Button type="link" onClick={() => handlerOpenModalResetPassword(record)}>
                        <LockOutlined />{ ' ' }Resetar senha
                    </Button>
                    <Button type="link" onClick={() => hidrateFormWithValues(record)}>
                        <EditOutlined />{ ' ' }Editar
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Deseja realmente excluir esse item?"
                        onConfirm={() => remove(record.id)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button type="link" style={{color: 'red'}} >
                            <DeleteOutlined />{ ' ' }Excluir
                        </Button>
                    </Popconfirm>                
                </Space>
            ),
        }
    ];

    const getData = useCallback( async () => {        
        try{
            setLoading(true)
            const { data } = await api.get('user', {params: {page: currentPage}})
            setDataTable(data)
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoading(false)
        }
    }, [currentPage])

    const onChangePage = (page: number) =>{
        setCurrentPage(page)
    }
    
    const hidrateFormWithValues = useCallback((values: any) => {
        form.setFieldsValue(values)
        setShowDrawer(true)
        setDrawerTitle('Editar cadastro')
        setErrors([])

    },[form])

    const handleToggleActive = async (id: string, active: boolean, record: any) => {
        try{
            record.active = active
            await api.put(`user/${id}`, record)
            message.success('Registro atualizado com sucesso')
        }catch(err: any){
            message.error(err.message)
        }   
    }
    
    const remove = useCallback(async (id: string) => {
        try{
            await api.delete(`user/${id}`)            
            message.success("Registro excluido com sucesso")
            getData()
        }catch(err: any){
            message.error(err.message)
        }
    }, [getData])
  
    const openDrawer = () => {
        setErrors([])        
        form.resetFields()
        setShowDrawer(true)        
        setDrawerTitle('Novo cadastro')
    }

    const saveOrCreate = async (values: any) => {
        if(values.id){
            try{
                await api.put(`user/${values.id}`, values)
                setShowDrawer(false);
                notification.open({
                    message: 'Sucesso',
                    description: 'Registro atualizado com sucesso',
                    icon: <CheckCircleOutlined style={{ color: 'green' }} />
                });         
                getData()
            }catch(err: any){
                setErrors(err?.response?.data?.errors)
            }
        }else{
            try{
                await api.post('user', values)
                setShowDrawer(false);
                notification.open({
                    message: 'Sucesso',
                    description: 'Registro inserido com sucesso',
                    icon: <CheckCircleOutlined style={{ color: 'green' }} />
                });           
                getData()
            }catch(err: any){
                setErrors(err?.response?.data?.errors)
            }
        }
    }  
    
    const searchProfile = async (search: string) => {
        try{
            setLoading(true)
            const { data } = await api.get(`profile`, {params: {filters: {name: search}}})            
            setProfileList(data)
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoading(false)
        }
    }

    const handlerOpenModalResetPassword = (user: any) => {
        setUser(user)
        setShowModalResetPassword(true)
    }

    const handlerResetPassword = async (values: any) => {
        try{
            await api.put(`user/${user?.id}/reset-password`, values)
            setShowModalResetPassword(false)
            message.success('Senha atualizada com sucesso')
        }catch(err: any){
            message.success(err.message)
        }
    }

    useEffect(() => {         
        getData()
        setPagination({})
    }, [getData])

    return(
        <LayoutFull headerTitle="Usuários">
            <Row>
                <Col offset={21} span={3} style={{justifyContent: 'flex-end', display: 'flex', marginBottom: 18}}>
                    <Button type="primary" block onClick={openDrawer}>
                        <PlusOutlined />{ ' ' }Novo
                    </Button>
                </Col>
            </Row>                      
            <Table 
                columns={columns} 
                dataSource={dataTable} 
                rowKey={() => Math.random()} 
                loading={loading}
                pagination={false}
                tableLayout="auto"   
            />
                <Pagination 
                    pageSize={20}
                    style={{float: 'right', margin: '5px'}}
                    current={currentPage} 
                    onChange={onChangePage} 
                    total={pagination.total}                    
                    showTotal={total => `Total ${total} registros`}
                />
                <Drawer
                    title={drawerTitle}
                    width={720}
                    onClose={() => setShowDrawer(false)}
                    maskClosable={false}
                    visible={showDrawer}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <Form 
                        layout="vertical" 
                        form={form} 
                        hideRequiredMark 
                        autoComplete="off"
                        onFinish={saveOrCreate}
                        initialValues={{
                            id: ''
                        }}
                    >
                    <Row>
                        <Form.Item name="id" style={{display: 'none'}}>
                            <Input readOnly name="id" />
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Nome"
                                rules={[{ required: true}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="username"
                                label="Usuário"
                                rules={[{ required: true}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="profile_id"
                                label="Perfil"
                                rules={[{ required: true}]}
                            >
                                <Select
                                    labelInValue={false}
                                    placeholder="Digite para buscar..."
                                    notFoundContent={!profileList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                    filterOption={false}
                                    onSearch={(value) => searchProfile(value)}
                                    style={{ width: '100%' }}
                                    showSearch
                                    allowClear
                                >
                                    {profileList.map((item: any) => (
                                        <Option key={item.id} value={item.id}>{item.description}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        { (!form.getFieldValue('id')) && (<Col span={12}>
                            <Form.Item
                                name="password"
                                label="Senha"
                                rules={[{ required: true}]}
                            >
                                <Input type="password" />
                            </Form.Item>
                        </Col>
                        )}
                        <Col span={12}>
                            <Form.Item
                                name="active"
                                label="Status"
                                rules={[{ required: true}]}
                            >
                            <Select placeholder="Status">
                                <Option value="true">Ativo</Option>
                                <Option value="false">Inativo</Option>
                            </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <Button onClick={() => setShowDrawer(false)} style={{ marginRight: 8 }} block htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col span={4}>                        
                            <Button type="primary" htmlType="submit" block>
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {Boolean(errors.length) && <InsideError errors={errors} />}
            </Drawer> 
            <Modal 
                title="Alterar senha" 
                visible={showModalResetPassword}
                onCancel={() => setShowModalResetPassword(false)}
                footer={false}
            >
                    <Form 
                        layout="vertical" 
                        form={form} 
                        hideRequiredMark 
                        autoComplete="off"
                        onFinish={handlerResetPassword}
                        initialValues={{
                            password: '',
                        }}
                    >
                    <Row>
                        <Col span={24}>  
                            <Form.Item
                                name="new_password"
                                label="Senha"
                                rules={[{ required: true}]}
                            >
                                <Input type="password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={19} span={5}>
                            <Button type="primary" htmlType="submit" block>
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </LayoutFull>
    )
}