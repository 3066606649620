import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Empty, Form, message, Row, Select, Spin } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import LayoutFull from "../../components/LayoutFull";
import api from '../../services/api.service';
import HarvestersService from "../../services/dashboard/HarvestersService";

type FormFilter = {
  date_start: string;
  date_end: string;
  plot_id: string;
  product_type_id: string;
  date_type: string;
  year: string;
}

export default function Dashboard(): JSX.Element {
  let yearDefault = moment().format('YYYY')

  const [collection, setCollection] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter)
  const [plotList, setPlotList] = useState<any[]>([])
  const [productList, setProductList] = useState<any[]>([])

  const { RangePicker } = DatePicker;
  const { Option } = Select

  const getData = useCallback(async () => {
    try {
      let params = { ...filters }
      params.year = filters.year || yearDefault
      setLoading(true)
      const data = await HarvestersService.execute(params)
      const dataTransformed: any[] = []

      data.forEach((item: any) => {
        dataTransformed.push({
          //semana: moment(item.provisioned_at).format('DD/MM/YYYY'),
          semana: `Semana ${item.week_day}`,
          provisioned_at: item.provisioned_at,
          previstos: Number(item.provisioneds),
          colhidos: Number(item.harvesteds),
          perdidos: Number(item.is_lost),
        })
      })
      setCollection(dataTransformed)
    } catch (err: any) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }, [filters, yearDefault])

  const handlerFilter = async (values: any) => {
    const [dateStart, dateEnd] = values.date || []

    if (values?.date) {
      const [year1, ,] = moment(dateStart).format(`YYYY-MM-DD`).split('-');
      const [year2, ,] = moment(dateEnd).format(`YYYY-MM-DD`).split('-');

      if (year1 !== year2) {
        message.error('O ano inicial e final devem ser iguais')
      }

      yearDefault = year1
    }
    setFilters({
      date_start: dateStart && moment(dateStart).format(`YYYY-MM-DD`),
      date_end: dateEnd && moment(dateEnd).format('YYYY-MM-DD'),
      date_type: values.date_type,
      plot_id: values.plot_id,
      product_type_id: values.product_type_id,
      year: yearDefault
    });
  }

  const searchPlot = async (search: string) => {
    try {
      setLoading(true)
      const { data } = await api.get(`plot`, { params: { filters: { name: search } } })
      setPlotList(data)
    } catch (err: any) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  const searchProduct = async (search: string) => {
    try {
      setLoading(true)
      const { data } = await api.get(`product-type`, { params: { filters: { name: search } } })
      setProductList(data)
    } catch (err: any) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  const getDateRangeOfWeek = (date: any): any => {
    const startDate = moment(date)
    const firstDay = moment(startDate).startOf('isoWeek').format('DD/MM/YYYY')
    const endDay = moment(startDate).endOf('isoWeek').format('DD/MM/YYYY')
    return `De ${firstDay} até ${endDay}`
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{label} {getDateRangeOfWeek(payload[0].payload.provisioned_at)}</p>
          <p className="label">previstos {payload[0].payload.previstos}</p>
          <p className="label">colhidos {payload[0].payload.colhidos}</p>
          <p className="label">perdidos {payload[0].payload.perdidos}</p>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <LayoutFull headerTitle="Dashboard">
      <div style={{ backgroundColor: '#fff', padding: 25 }}>
        <Form
          initialValues={{ date_type: 'provisioned_at' }}
          onFinish={handlerFilter}
          onFinishFailed={() => { }}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item
                name="plot_id"
                label="Lote"
              >
                <Select
                  labelInValue={false}
                  placeholder="Digite o nome do lote para buscar..."
                  notFoundContent={!plotList.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                  filterOption={false}
                  onSearch={(value) => searchPlot(value)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                >
                  {(plotList || []).map((item: any) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="product_type_id"
                label="Variedade Fruta"
              >
                <Select
                  labelInValue={false}
                  placeholder="Digite o nome da fruta..."
                  notFoundContent={!productList.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                  filterOption={false}
                  onSearch={(value) => searchProduct(value)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                >
                  {(productList || []).map((item: any) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="date"
                label="Período (Dia/Mês)"
              >
                <RangePicker format='DD/MM' placeholder={['Dia/mês Inicial', 'Dia/mês Final']} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{ marginTop: 30 }}>
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            {loading && <Spin />}
          </Col>
          {(!loading && Boolean(collection.length)) && (
            <Col span={24}>
              <h1>Demonstrativo de Previstos x Colhidos x Perdidos</h1>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart width={100} height={100} data={collection}>
                  <CartesianGrid strokeDasharray="1 2" color="#aaa" />
                  <XAxis dataKey="semana" type="category" allowDuplicatedCategory={false} tick={{ fill: "#aaa" }} />
                  <YAxis fill="#aaa" tick={{ fill: "#aaa" }} />
                  <Tooltip content={<CustomTooltip />} />
                  <Line dataKey="previstos" fill="blue" />
                  <Line dataKey="colhidos" fill="green" />
                  <Line dataKey="perdidos" fill="red" />
                </LineChart>
              </ResponsiveContainer>
            </Col>
          )}
          {(!loading && !Boolean(collection.length)) && (
            <Col span={24} style={{ textAlign: 'center' }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{
                  height: 60,
                }}
                description="Nenhum registro encontrado"
              >
              </Empty>
            </Col>
          )}
        </Row>
      </div>
    </LayoutFull>
  );
}
