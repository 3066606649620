import { BrowserRouter, Route, Routes } from "react-router-dom"
import Dashboard from "../screens/Dashboard"
import { HarvestForecast } from "../screens/HarvestForecast"
import { HarvestMoviments } from "../screens/HarvestMoviments"
import { HarvestPackaged } from "../screens/HarvestPackaged"
import { HarvestPackagedLoss } from "../screens/HarvestPackagedLoss"
import { PackageType } from "../screens/PackageType"
import { Plot } from "../screens/Plot"
import { ProductType } from "../screens/ProductType"
import { ProductTypeQuality } from "../screens/ProductTypeQuality"
import { Profile } from "../screens/Profile"
import { HarvestReport } from "../screens/Report/HarvestReport"
import { PackagedLossReport } from "../screens/Report/PackageLossReport"
import { PackageReport } from "../screens/Report/PackageReport"
import { User } from "../screens/User"

export function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/product-type" element={<ProductType />} />
                <Route path="/product-type-quality" element={<ProductTypeQuality />} />
                <Route path="/package-type" element={<PackageType />} />
                <Route path="/harvest-forecast" element={<HarvestForecast />} />
                <Route path="/harvest-moviments" element={<HarvestMoviments />} />
                <Route path="/harvest-packaged" element={<HarvestPackaged />} />
                <Route path="/harvest-packaged-loss" element={<HarvestPackagedLoss />} />
                <Route path="/plot" element={<Plot />} />
                <Route path="/user" element={<User />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/reports/harvest" element={<HarvestReport />} />
                <Route path="/reports/package" element={<PackageReport />} />
                <Route path="/reports/package-loss" element={<PackagedLossReport />} />
            </Routes>
        </BrowserRouter>
    )
}