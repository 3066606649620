import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, Row } from 'antd';
import { useState } from 'react';
import Logo from '../../assets/images/logo.png';
import InsideError from '../../components/InsideError';
import { useAuth } from '../../contexts/auth.context';

export default function Login() {

    const { doLogin } = useAuth();

    const [errors, setErrors] = useState<string | string[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const handlerOnSubmit = async (data: any) => {
    try{
        setLoading(true)
        await doLogin(data)
    }catch(err: any){
        setErrors(err.message)
    }finally{
        setLoading(false)
    }
}

return (
    <Layout>
        <Row        
            style={{
                height: '100%', 
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                flex: 1,
                display: 'flex'
            }}
        >
            <Col span={12}
                style={{
                    height: '100%', 
                }}
            >

            </Col>
            <Col span={12}
                style={{
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Row>
                    <Col span={12} 
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        <Form
                            initialValues={{ remember: true }}
                            onFinish={handlerOnSubmit} 
                            style={{
                                backgroundColor: '#fff',
                                padding: 50
                            }}      
                        >
                        <img 
                            src={Logo} 
                            alt='' 
                            width={120}
                            style={{
                                margin: 20,
                                display: 'inline'
                            }}
                        />
                            <Form.Item
                                name="username"
                                rules={[{ required: true }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button 
                                    type="primary" 
                                    htmlType="submit" 
                                    block
                                    className="login-form-button"
                                    disabled={loading}
                                >
                                    Entrar
                                </Button>
                            </Form.Item>
                            { Boolean(errors) && <InsideError errors={errors}/>}
                        </Form>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Layout>
    );
}
