import ptBr from 'antd/es/locale/pt_BR';
import { ConfigProvider } from 'antd';
import { AuthProvider } from "./contexts/auth.context"
import { Routes } from "./routes"

function App() {
  return (
    <ConfigProvider locale={ptBr}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </ConfigProvider>
  )
}

export default App
