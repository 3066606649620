import { useCallback, useEffect, useState } from "react";
import { message, Table, Form, Button, DatePicker, Select, Spin, Row, Col } from "antd";
import { SearchOutlined, FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment';
import api from "../../../services/api.service";
import LayoutFull from "../../../components/LayoutFull";
import CustomPagination from "../../../components/CustomPagination";
import getDateRangeOfWeek from "../../../utils/getDateRangeOfWeek";

type FormFilter = {
    date_start: string;
    date_end: string;
    plot_id: string;
    product_type_id: string;
    date_type: string;
}

export function HarvestReport(){

    const [dataTable, setDataTable] = useState([])
    
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingButtonPdf, setLoadingButtonPdf] = useState<boolean>(false)

    const [filters, setFilters] = useState<FormFilter>({} as FormFilter)
    const [plotList, setPlotList] = useState<any[]>([])
    const [productList, setProductList] = useState<any[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    
    const { Option } = Select
    
    const columns: any[] = [
        {
            title: 'Lote',
            key: 'plot',
            dataIndex: 'plot',
            render: (value: string, record: any) => {
                return value
            },
        },
        {
            title: 'Previsão de colheita',
            key: 'provisioned_at',
            dataIndex: 'provisioned_at',
            render: (value: string, record: any) => {
                return (
                    <div>                      
                        <strong>
                            Semana: {moment(value).isoWeeks()}
                        </strong>
                        <br/>
                        <span>{getDateRangeOfWeek(moment(value).isoWeeks(), moment(value).year())}</span>
                    </div>
                )
            },
        },
        {
            title: 'Semana do corte',
            key: 'created_at',
            dataIndex: 'created_at',
            render: (value: string, record: any) => {
                return(
                    <div>
                        <strong>Semana: {moment(value).isoWeeks()}</strong>
                        <br/>
                        <span>{moment(value).utc().format('DD/MM/YYYY')}</span>
                    </div>
                )
            },
        }, 
        {
            title: 'Duração',
            key: 'duration',
            dataIndex: 'duration',
            ellipsis: 'enable',
            render: (value: string, record: any) => {
                return `${value} dias`
            }
        },   
        {
            title: 'Previstos',
            key: 'provisioneds',
            dataIndex: 'provisioneds',
            sorter: (a: any, b: any) => a.provisioneds.length - b.provisioneds.length,
        }, 
        {
            title: 'Perdidos',
            key: 'is_lost',
            dataIndex: 'is_lost',
            sorter: (a: any, b: any) => a.is_lost.length - b.is_lost.length,
        }, 
        {
            title: 'Colhidos',
            key: 'harvesteds',
            dataIndex: 'harvesteds',
            sorter: (a: any, b: any) => a.harvesteds.length - b.harvesteds.length,
        }, 
        {
            title: 'Falta colher',
            key: 'a',
            dataIndex: 'a',
            render: (value: string, record: any) => {
                return  (record.provisioneds - record.is_lost - record.harvesteds)
            }
        }
    ];

    const getData = useCallback( async () => {
        try{
            const params = {...filters, ...{page: currentPage}}
            setLoading(true)
            const { data } = await api.get('reports/harvest-forecast', {params})
            setDataTable(data)
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoading(false)
        }
    }, [currentPage, filters])

    const handlerFilter = async (values: any) => {        
        setCurrentPage(1)
        setFilters({
            date_start: values.date_start && moment(values.date_start).format('YYYY-MM-DD'), 
            date_end: values.date_end && moment(values.date_end).format('YYYY-MM-DD'),
            date_type: values.date_type,
            plot_id: values.plot_id,
            product_type_id: values.product_type_id,
        });
    }

    const handlerDownloadPdf = async() => {
        try{
            setLoadingButtonPdf(true)
            const { data } = await api.get('reports/harvest-forecast/pdf', {params: filters, responseType: 'blob'})
            const file = new Blob([data], {type: 'application/pdf'});
            const url = URL.createObjectURL(file);
            window.open(url);
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoadingButtonPdf(false)
        }
    }

    const searchPlot = async (search: string) => {
        try{
            setLoading(true)
            const { data } = await api.get(`plot`, {params: {filters: {name: search}}})            
            setPlotList(data)
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoading(false)
        }
    }

    const searchProduct = async (search: string) => {
        try{
            setLoading(true)
            const { data } = await api.get(`product-type`, {params: {filters: {name: search}}})            
            setProductList(data)
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [getData])

    return(
        <LayoutFull headerTitle="Relatório previsão de colheita">
            <Form
                initialValues={{ date_type: 'provisioned_at' }}
                onFinish={handlerFilter}
                onFinishFailed={() => {}}
                autoComplete="off"
                layout="vertical"
            >
                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item
                            name="plot_id"
                            label="Lote"                 
                        >
                            <Select
                                labelInValue={false}
                                placeholder="Digite o nome do lote para buscar..."
                                notFoundContent={!plotList.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                filterOption={false}
                                onSearch={(value) => searchPlot(value)}
                                style={{ width: '100%' }}
                                showSearch
                                allowClear
                            >
                                {(plotList || []).map((item: any) => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="product_type_id"
                            label="Variedade Fruta"                 
                        >
                            <Select
                                labelInValue={false}
                                placeholder="Digite o nome da fruta..."
                                notFoundContent={!productList.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                filterOption={false}
                                onSearch={(value) => searchProduct(value)}
                                style={{ width: '100%' }}
                                showSearch
                                allowClear
                            >
                                {(productList || []).map((item: any) => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>  
                        <Row gutter={8}>
                            <Col span={12}>                      
                                <Form.Item                        
                                    name="date_start"
                                    label="Data inicial" 
                                >
                                    <DatePicker format='DD/MM/YYYY' placeholder={'Data Inicial'}/>
                                </Form.Item>   
                            </Col>
                            <Col span={12}>                               
                                <Form.Item                        
                                    name="date_end"
                                    label="Data Final" 
                                >
                                    <DatePicker format='DD/MM/YYYY' placeholder={'Data Final'}/>
                                </Form.Item> 
                            </Col>
                        </Row>       
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            name="date_type"
                            label="Tipo de data"                 
                        >
                            <Select
                                labelInValue={false}
                                placeholder="Selecione"
                                notFoundContent={!plotList.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                filterOption={false}
                                onSearch={(value) => {}}
                                style={{ width: '100%' }}
                                showSearch
                                allowClear
                            >
                                <Option key={1} value="created_at" selected>Data de corte</Option>
                                <Option key={2} value="provisioned_at">Data de previsão</Option>
                                {/* <Option key={3} value="harvest_at">Data de colheita</Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{marginTop: 30}}>
                            Buscar
                        </Button>
                        <Button 
                            onClick={() => handlerDownloadPdf()} 
                            htmlType="button" 
                            icon={<FilePdfOutlined />} 
                            style={{marginTop: 30, backgroundColor: '#3d85c6', color: '#fff', marginLeft: 8}}
                            loading={loadingButtonPdf}
                        >
                            PDF
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Table 
                columns={columns} 
                dataSource={dataTable} 
                rowKey={() => Math.random()} 
                loading={loading}
                pagination={false}
            />
            <CustomPagination 
                onPrevious={() => setCurrentPage(currentPage - 1)}
                onNext={() => setCurrentPage(currentPage + 1)}
                currentPage={currentPage}
                dataTableLength={Boolean(dataTable.length)}
            />
        </LayoutFull>
    )
}