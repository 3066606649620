import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from "../screens/Login"

export function AuthRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
            </Routes>
        </BrowserRouter>
    )
}