import { Alert } from "antd";

export default function InsideError({ errors }: any){

    let errorConverted: string[] = errors

    if(typeof errors == 'string'){
        errorConverted = [errors]
    }

    return(
        <ul style={{listStyle: 'none'}}>
            {errorConverted.map((item: any) => (                        
                <li key={item}>
                    <Alert message={item} type="error" style={{marginTop: 10}}/>                            
                </li>                        
            ))}
        </ul>
    )
}