import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { CredentialProps } from '../models/CredentialsProps';
import Authentication from '../services/Authentication';

export type AuthContextUserProps = {
    name: string;
    profile: string;
    username: string;
}

export type AuthContextProps = {
    signed: boolean;
    user: AuthContextUserProps | null;
    doLogin(values: CredentialProps): Promise<void>;
    doLogout(): void;
}

type AuthProviderProps = {
    children: React.ReactNode;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider = ({ children }: AuthProviderProps) => {

    const [user, setUser] = useState<AuthContextUserProps>({} as AuthContextUserProps);

    const getDataStoraged = useCallback(() => {
        const storagedUser = localStorage.getItem('@AgroMobi:user')
        const storagedToken = localStorage.getItem('@AgroMobi:token')

        if (storagedUser && storagedToken) {
            setUser(JSON.parse(storagedUser))
        }
    }, [])

    async function doLogin(credentials: CredentialProps) {
        try {
            const data = await Authentication.signin(credentials)
            setUser(data.user)
            localStorage.setItem('@AgroMobi:user', JSON.stringify(data.user))
            localStorage.setItem('@AgroMobi:token', data.access_token)
        } catch (err: any) {
            throw Error(err.message)
        }
    }

    async function doLogout() {
        localStorage.removeItem('@AgroMobi:token')
        localStorage.removeItem('@AgroMobi:user')
        setUser({} as AuthContextUserProps)
    }

    useEffect(() => {
        getDataStoraged()
    }, [getDataStoraged])

    return (
        <AuthContext.Provider value={{ signed: Boolean(user.name), user, doLogin, doLogout }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext);
    return context;
}