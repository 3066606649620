import {
  MenuFoldOutlined, MenuUnfoldOutlined, DownOutlined
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, Menu } from "antd";
import { useState } from "react";
import Footer from "../Footer";
import MenuSidebar from "../MenuSidebar";
import Logo from '../../assets/images/logo-full.png'
import { useAuth } from '../../contexts/auth.context';
import { getNameInitials } from '../../utils/getNameInitials';

interface LayoutFullProps {
  children: any;
  headerTitle?: string;
  headerIcon?: any;
};

export default function LayoutFull({
  children,
  headerTitle,
  headerIcon,
  ...rest
}: LayoutFullProps) {

  const { doLogout } = useAuth()

  const { Header, Sider, Content } = Layout;
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const nameInitals: string = getNameInitials()

  const menuDropdown = (
    <Menu>
      <Menu.Item danger onClick={() => doLogout()}>Sair</Menu.Item>
    </Menu>
  );

  return (
    <Layout {...rest}>
      <Sider
        trigger={null}
        collapsible
        collapsed={menuCollapsed}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0
        }}
      >
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', padding: 12 }}>
          <img src={Logo} height={60} alt="" />
        </div>
        <MenuSidebar />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: (menuCollapsed) ? 80 : 200 }}>
        <Header className="site-layout-background">
          {menuCollapsed ?
            <MenuUnfoldOutlined className="trigger" onClick={() => setMenuCollapsed(!menuCollapsed)} />
            :
            <MenuFoldOutlined className="trigger" onClick={() => setMenuCollapsed(!menuCollapsed)} />
          }
          <h2 style={{ padding: 0, margin: 0 }}>{headerTitle}</h2>
          <div
            style={{
              position: 'fixed',
              right: 10
            }}
          >
            <Dropdown
              overlay={menuDropdown}
            >
              <Button type="link">
                <Avatar style={{ backgroundColor: 'red', verticalAlign: 'middle' }} size="large">
                  {nameInitals}
                </Avatar>
                <strong
                  className="ant-dropdown-link"
                  style={{
                    marginLeft: 12,
                    color: "#000"
                  }}
                >
                  Minha Conta <DownOutlined />
                </strong>
              </Button>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            margin: '60px 0',
            padding: 24
          }}
        >
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}
