import api from "../api.service";

export default class HarvestersService{
    static async execute(params: any){
        try{
            const { data } = await api.get('dashboard/harvesters', {params});
            return data;
        }catch(error: any){
            throw Error(error)
        }
    }
}