export const getNameInitials = () => {
  
    let userStoraged = {name: 'Agro Mobi'}

    if(localStorage.getItem('@AgroMobi:user')){
      userStoraged = JSON.parse(localStorage.getItem('@AgroMobi:user') || '')
    }

    const fullName: any = userStoraged.name
    const [firstName, lastName] = fullName.split(' ');
    const firstInitial = firstName?.substring(0, 1).toUpperCase() || '';
    const lastInitial = lastName?.substring(0, 1).toUpperCase() || '';  
    return `${firstInitial}${lastInitial}`;
  };