
export default function getDateRangeOfWeek(weekNumber: number, yearNumber: number) {

  let d1, numOfdaysPastSinceLastMonday;
  d1 = new Date(Date.UTC(yearNumber, 1, 0, 0, 0, 0));

  var date = new Date(d1.getTime());
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  var week1 = new Date(date.getFullYear(), 0, 4);

  const week = 1 + Math.round(((d1.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);

  numOfdaysPastSinceLastMonday = d1.getDay() - 1;

  d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
  d1.setDate(d1.getDate() + (7 * (weekNumber - week)));

  const month = formatMonth2Alg(d1)
  const dayTo = d1.getDate()
  const year = d1.getFullYear()
  d1.setDate(d1.getDate() + 6);

  const dayFrom = d1.getDate();
  const monthFrom = formatMonth2Alg(d1)

  return `${dayTo}/${month}/${year} à ${dayFrom}/${monthFrom}/${year}`;
};


function formatMonth2Alg(day: Date) {
  return (day.getMonth()) < 9 ? `0${(day.getMonth() + 1)}` : (day.getMonth() + 1)
}