
import {
  CalendarOutlined, ClusterOutlined, DashboardOutlined, InboxOutlined, SettingOutlined, UserOutlined, FieldTimeOutlined, FileSearchOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuSidebar: React.FC = () => {

    return(
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['painel']}>
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <NavLink to="/dashboard">Dashboard</NavLink>
          </Menu.Item>
          <SubMenu key="register" icon={<UserOutlined />} title="Cadastros">
            <Menu.Item key="user" icon={<UserOutlined />}>
              <NavLink to="/user">Usuários</NavLink>
            </Menu.Item>
            <Menu.Item key="profile" icon={<UserOutlined />}>
              <NavLink to="/profile">Perfis</NavLink>
            </Menu.Item>
            <Menu.Item key="producttype" icon={<ClusterOutlined />}>
              <NavLink to="/product-type">Culturas</NavLink>
            </Menu.Item>
            <Menu.Item key="plot" icon={<ClusterOutlined />}>
              <NavLink to="/plot">Unidades de produção</NavLink>
            </Menu.Item>
            <Menu.Item key="productqualities" icon={<InboxOutlined />}>
              <NavLink to="/product-type-quality">Classificação</NavLink>
            </Menu.Item>
            <Menu.Item key="packages" icon={<InboxOutlined />}>
              <NavLink to="/package-type">Embalagens</NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="moviments" icon={<FileSearchOutlined />} title="Lançamentos">                    
            <Menu.Item key="harvestforecast" icon={<CalendarOutlined />}>
              <NavLink to="/harvest-forecast">Corte</NavLink>
            </Menu.Item>                     
            <Menu.Item key="harvestmoviments" icon={<CalendarOutlined />}>
              <NavLink to="/harvest-moviments">Colheita</NavLink>
            </Menu.Item>    
            <Menu.Item key="harvestpackaged" icon={<FieldTimeOutlined />}>
              <NavLink to="/harvest-packaged">Embalagem</NavLink>
            </Menu.Item>   
            <Menu.Item key="harvestpackagedloss" icon={<FieldTimeOutlined />}>
              <NavLink to="/harvest-packaged-loss">Refugo</NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="reports" icon={<FileSearchOutlined />} title="Relatórios">        
            <Menu.Item key="reportsharvest" icon={<FileSearchOutlined />}>
              <NavLink to="/reports/harvest">Previsão de colheita</NavLink>
            </Menu.Item>     
            <Menu.Item key="reportspackage" icon={<FileSearchOutlined />}>
              <NavLink to="/reports/package">Embalagem</NavLink>
            </Menu.Item>
            <Menu.Item key="reportspackageloss" icon={<FileSearchOutlined />}>
              <NavLink to="/reports/package-loss">Refugo</NavLink>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="settigns" icon={<SettingOutlined />}>
            <NavLink to="/settigns">Configurações</NavLink>
          </Menu.Item>
        </Menu>
    )
}

export default MenuSidebar
