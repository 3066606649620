import {
    CheckCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined
} from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Form, Input, message, notification, Pagination, Popconfirm, Row, Select, Space, Switch, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import InsideError from '../../components/InsideError';
import LayoutFull from "../../components/LayoutFull";
import api from "../../services/api.service";

export function PackageType(){

    const [dataTable, setDataTable] = useState([])
    const [loading, setLoading] = useState<boolean>(false)
    const [pagination, setPagination] = useState({} as any)
    const [errors, setErrors] = useState([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [showDrawer, setShowDrawer] = useState(false)
    const [drawerTitle, setDrawerTitle] = useState('')

    const [form] = Form.useForm()
    const { Option } = Select 

    const columns: any[] = [
        {
          title: 'Nome',
          key: 'name',
          dataIndex: 'name',
          ellipsis: 'enable',
          sorter: (a: any, b: any) => a.name.length - b.name.length,
        },
        {
            title: 'Peso (KG)',
            key: 'weigth',
            dataIndex: 'weigth',
            sorter: (a: any, b: any) => a.weigth.length - b.weigth.length,
            render: (value: string) => {
                return `${value} Kg`
            }
          },        
        {
            title: 'Ativo',
            dataIndex: 'active',
            key: 'active',
            render: (value: boolean, record: any) => {
              return (
                <Switch size="small" defaultChecked={value} onClick={(value) => handleToggleActive(record.id, value, record)} />
              )
            },
            sorter: (a: any, b: any) => a?.active - b?.active,
          },        
        {
            title: 'Ações',
            key: 'actions',
            align: 'center',
            render: (text: any, record: any) => (
                <Space size="small">
                    <Button type="link" onClick={() => hidrateFormWithValues(record)}>
                        <EditOutlined />{ ' ' }Editar
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Deseja realmente excluir esse item?"
                        onConfirm={() => remove(record.id)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button type="link" style={{color: 'red'}} >
                            <DeleteOutlined />{ ' ' }Excluir
                        </Button>
                    </Popconfirm>                
                </Space>
            ),
        }
    ];

    const getData = useCallback( async () => {
        try{
            setLoading(true)
            const { data } = await api.get('package-type', {params: {page: currentPage}})
            setDataTable(data)
        }catch(err: any){
            message.error(err.message)
        }finally{
            setLoading(false)
        }
    }, [currentPage])

    const onChangePage = (page: number) =>{
        setCurrentPage(page)
    }
    
    const hidrateFormWithValues = useCallback((values: any) => {
        form.setFieldsValue(values)
        setShowDrawer(true)
        setDrawerTitle('Editar cadastro')
        setErrors([])

    },[form])

    const handleToggleActive = async (id: string, active: boolean, record: any) => {
        try{
            record.active = active
            await api.put(`package-type/${id}`, record)
            message.success('Registro atualizado com sucesso')
        }catch(err: any){
            message.error(err?.response?.data?.message)
        }   
    }
    
    const remove = useCallback(async (id: string) => {
        try{
            await api.delete(`package-type/${id}`)            
            message.success("Registro excluido com sucesso")
            getData()
        }catch(err: any){
            message.error(err.message)
        }
    }, [getData])
  
    const openDrawer = () => {
        setErrors([])        
        form.resetFields()
        setShowDrawer(true)        
        setDrawerTitle('Novo cadastro')
    }

    const saveOrCreate = async (values: any) => {
        if(values.id){
            try{
                await api.put(`package-type/${values.id}`, values)
                setShowDrawer(false);
                notification.open({
                    message: 'Sucesso',
                    description: 'Registro atualizado com sucesso',
                    icon: <CheckCircleOutlined style={{ color: 'green' }} />
                });         
                getData()
            }catch(err: any){
                setErrors(err?.response?.data?.errors)
            }
        }else{
            try{
                await api.post('package-type', values)
                setShowDrawer(false);
                notification.open({
                    message: 'Sucesso',
                    description: 'Registro inserido com sucesso',
                    icon: <CheckCircleOutlined style={{ color: 'green' }} />
                });           
                getData()
            }catch(err: any){
                setErrors(err?.response?.data?.errors)
            }
        }
    }      

    useEffect(() => {         
        getData()
        setPagination({})
    }, [getData])

    return(
        <LayoutFull headerTitle="Embalagens">
            <Row>
                <Col offset={21} span={3} style={{justifyContent: 'flex-end', display: 'flex', marginBottom: 18}}>
                    <Button type="primary" block onClick={openDrawer}>
                        <PlusOutlined />{ ' ' }Novo
                    </Button>
                </Col>
            </Row>                      
            <Table 
                columns={columns} 
                dataSource={dataTable} 
                rowKey={() => Math.random()} 
                loading={loading}
                pagination={false}
            />
                <Pagination 
                    pageSize={20}
                    style={{float: 'right', margin: '5px'}}
                    current={currentPage} 
                    onChange={onChangePage} 
                    total={pagination.total}                    
                    showTotal={total => `Total ${total} registros`}
                />
                <Drawer
                    title={drawerTitle}
                    width={720}
                    onClose={() => setShowDrawer(false)}
                    maskClosable={false}
                    visible={showDrawer}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <Form 
                        layout="vertical" 
                        form={form} 
                        hideRequiredMark 
                        onFinish={saveOrCreate}
                        initialValues={{
                            id: ''
                        }}
                    >
                    <Row gutter={16}>
                        <Form.Item name="id" style={{display: 'none'}}>
                            <Input readOnly name="id" />
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Nome"
                                rules={[{ required: true}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="weigth"
                                label="Peso (KG)"
                                rules={[{ required: true}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="active"
                                label="Status"
                                rules={[{ required: true}]}
                            >
                            <Select placeholder="Status">
                                <Option value="true">Ativo</Option>
                                <Option value="false">Inativo</Option>
                            </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Button onClick={() => setShowDrawer(false)} style={{ marginRight: 8 }} block htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col span={4}>                        
                            <Button type="primary" htmlType="submit" block>
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                    </Form>
                    {Boolean(errors.length) && <InsideError errors={errors} />}
                </Drawer> 
        </LayoutFull>
    )
}